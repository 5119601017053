import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`10:00 EMOM of the complex:`}</p>
    <p>{`1-Clean Grip Deadlift from floor to`}</p>
    <p>{`:05 pause just below the knee + 1 Clean Grip DL to`}</p>
    <p>{`1-Jump Shrug from hang just above the knee`}</p>
    <p><em parentName="p">{`*`}{`start with 80% 1RM Clean and add weight each minute if possible`}</em></p>
    <p>{`then,`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`12-KBS’s (53/35)`}</p>
    <p>{`10-Box Jump Overs (24/20)`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`We still need a few judges (level 1 or past judging experience is
preferred) for our Granite Games Spring Throwdown. Today is the last day
to get your volunteer t shirt so if you can, please consider helping.
 **`}</strong>{`Sign up to volunteer
at: `}<a parentName="p" {...{
        "href": "https://competitioncorner.net/events/3164"
      }}>{`https://competitioncorner.net/events/3164`}</a>{`***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      